import React, { Component } from 'react';
import Footer from '../components/footer';
import {Helmet} from "react-helmet";

const url = typeof window !== 'undefined' ? window.location.pathname.split("/")[2] : '';

class Product extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: [],
            loading: false
        }
    }

    componentDidMount() {
        var searchUrl = `https://cemedicament.ro/api/product.php?id=${url}`;
        this.setState({ loading: true });

        fetch(searchUrl,
            {
                method: "GET",
                headers: {
                    'Content-type': 'application/json'
                },
            }
        )
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.setState({ data, loading: false });
            });
    }

    render() {

        let meta = [];
        meta = this.state.data[0];
        
        return (

            <>
                <Helmet>
                    <title>{meta ? `Ce medicament? - ${meta.name}` : `Ce medicament?`}</title>

                    <meta name="description" content={meta ? `${meta.description}` : `Singurul motor de cautare pentru medicamente`} />
                    <meta name="robots" content="index, follow" />

                    <link rel="icon" href="/favicon.ico" />
                    <link rel="apple-touch-icon" href="/logo192.png" />

                    <script data-ad-client="ca-pub-2333940847398247" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

                    {/* Open Graph / Facebook */}
                    <meta property="og:title" content={meta ? `Ce medicament? - ${meta.name}` : `Ce medicament?`} />
                    <meta property="og:description" content={meta ? `${meta.description}` : `Singurul motor de cautare pentru medicamente`} />

                    {/* Twitter */}
                    <meta property="twitter:title" content={meta ? `Ce medicament? - ${meta.name}` : `Ce medicament?`} />
                    <meta property="twitter:description" content={meta ? `${meta.description}` : `Singurul motor de cautare pentru medicamente`} />
                </Helmet>

                <div id="root" className="uk-height-viewport uk-flex uk-flex-column">

                    {this.state.loading && <div className="lds-facebook">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>}

                    <div className="uk-section uk-section-default">
                        {this.state.data.map((medicament, id) => (
                            <div key={id} className="uk-container">
                                <div className="uk-text-center">
                                    <a href="/">
                                        <img src="/logo.svg" width="100" height="" alt="" />
                                    </a>
                                    <h1 className="uk-heading-medium uk-text-center">{medicament.name}</h1>
                                </div>
                                <br/>
                                <div className="uk-container uk-text-center">
                                    <a href="https://event.2performant.com/events/click?ad_type=banner&unique=f5aa093fd&aff_code=b2dea38f3&campaign_unique=6390e3cfb" target="_blank" rel="nofollow"><img src="https://img.2performant.com/system/paperclip/banner_pictures/pics/158285/original/158285.png" alt="drmax.ro/%20" title="drmax.ro/%20" border="0" height="330px" width="1140px"/></a>
                                </div>
                                <br/>
                                <div>
                                    <h4><strong>Ce este {medicament.name} şi pentru ce se utilizează</strong></h4>
                                    <p dangerouslySetInnerHTML={{ __html: medicament.description }} />
                                    <h4><strong>Ce conţine {medicament.name}</strong></h4>
                                    <p dangerouslySetInnerHTML={{ __html: medicament.substance }} />
                                    <p>Vezi tot prospectul de la ANM <a target="_blank" href={medicament.url} rel="noopener noreferrer">aici</a></p>
                                </div>
                                <div className="uk-text-center">
                                    <a href="/">
                                        <button className="uk-button uk-button-primary uk-button-large">Cautare noua</button>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>

                <Footer />

            </>
        );
    }
}

export default Product;
